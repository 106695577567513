// @flow
import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { useI18n } from 'internationalization';
import { TicketsPageDisplay, useTicketsPageDisplay } from 'components/Tickets';
import { useClearAssignmentNotifications } from '../Jobs/hooks';
import ActivityStreamItem from './ActivityStreamItem';
import TicketStreamItem from './TicketStreamItem';
import styles from './styles.module.scss';

type ActivityStreamProps = {
  assignment: any,
};

const ActivityStream = ({ assignment }: ActivityStreamProps) => {
  const intlz = useI18n();
  const [openTicketsPage, pageDisplayProps] = useTicketsPageDisplay();
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);

  // Clear the notifications when the user views the page
  const clearNotifications = useClearAssignmentNotifications();
  useEffect(() => {
    if (assignment) clearNotifications(assignment.id);
  }, [assignment, clearNotifications]);

  return (
    <div className={styles.activityStream}>
      {assignment.assignmentInstances
        .sort((a, b) => moment(b.loggingStart).diff(moment(a.loggingStart)))
        .map((ai) => (
          <Fragment key={ai.id}>
            {ai.loggingStop && (
              <ActivityStreamItem date={ai.loggingStop}>
                {intlz('Stopped logging')}
              </ActivityStreamItem>
            )}
            {ai.tickets
              .filter((t) => !t.deleted)
              .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
              .map((t) => (
                <TicketStreamItem
                  key={t.id}
                  ticket={t}
                  user={t.createdBy}
                  signoffEnabled={assignment.project.signOffRequired}
                  onThumbnailClick={() => {
                    setSelectedInstanceId(ai.id);
                    openTicketsPage(TicketsPageDisplay.Steps.editTicket, t.id);
                  }}
                  onSignoff={() => {
                    setSelectedInstanceId(ai.id);
                    openTicketsPage(TicketsPageDisplay.Steps.getSignature, t.id);
                  }}
                />
              ))}
            {ai.loggingStart && (
              <ActivityStreamItem date={ai.loggingStart}>
                {intlz('Started logging')}
              </ActivityStreamItem>
            )}
          </Fragment>
        ))}
      <TicketsPageDisplay
        assignment={assignment}
        instanceId={selectedInstanceId}
        tickets={assignment.assignmentInstances.map((ai) => ai.tickets).flat()}
        signoffEnabled={assignment.project.signOffRequired}
        {...pageDisplayProps}
      />
    </div>
  );
};

export default ActivityStream;
