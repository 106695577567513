// @flow
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import { Button, TextOnlyButton } from 'common-ui/Button';
import {
  Pen,
  Close,
} from 'common-ui/Icon';
import { useFormikOCR } from 'common-ui/Upload';
import {
  FormikTextInput,
  FormikTextArea,
  FormField,
  FormikDropdown,
} from 'common-ui/Form';
import ClickableDiv from 'common-ui/ClickableDiv';
import { useI18n } from 'internationalization';
import { SignedOffTag } from 'components/ActivityStream/TicketStreamItem';
import ID from 'common-ui/ConsistentIDs';
import type { Ticket, Assignment } from 'common-ui/types';
import { TicketZoomer } from 'common-ui/Tickets';
import { SignOffPageJobInfoDetails } from 'components/Common/JobInfoDetails';
import { default as ConfigurableFields } from 'common-ui/ConfigurableFields';
import {
  rateUnitOptions,
  useGetRateAndQuantity,
} from 'utils/format/rateUnits';
import OCRUpload from './OCRUpload';
import AttachmentUpload from './AttachmentUpload';
import TicketHeader from './TicketHeader';
import { useAddTicket, useUpdateTicket, useDeleteTicket } from './gql';
import { Steps } from './constants';
import FormikMobileTimePicker from '../Common/FormikMobileTimePicker';
import { CardDivider } from '../../common-ui/Cards';
import TicketFieldTypes from '../../common-ui/Tickets/TicketFieldTypes';
import { isAfter, isValidFloat } from 'common-ui/Utils/utils';
import { default as useDriverConfigurableFields } from '../../Hooks/useDriverConfigurableFields';
import styles from './styles.module.scss';
import moment from 'moment';
import Page from 'components/Common/Page';
import { Redirect, useHistory } from 'react-router-dom';

type SpinnerProps = {
  visible: boolean,
};

const Spinner = ({ visible }: SpinnerProps) => {
  const intlz = useI18n();

  return (
    <div className={classNames(styles.spinnerContainer, visible && styles.visible)}>
      <div className={styles.spinner} />
      {intlz('Scan in progress. Any ticket information that is recognized will auto-fill fields.')}
    </div>
  );
};

type AddEditTicketFormProps = {
  ticket: Ticket | null,
  assignment?: Assignment,
  instanceId: string,
  signoffEnabled?: boolean,
  onComplete: () => void,
  onSignoff?: () => void | null,
  onRemove?: () => void | null,
  setSelectedTicketId: (string) => void,
  currentStep?: string | null,
  displayJobInfo?: boolean,
};

const AddEditTicketForm = ({
  ticket,
  assignment,
  instanceId,
  signoffEnabled,
  onComplete,
  onSignoff,
  onRemove,
  setSelectedTicketId,
  currentStep,
  displayJobInfo,
}: AddEditTicketFormProps) => {
  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef();
  const intlz = useI18n();
  const addTicket = useAddTicket(instanceId, assignment.id);
  const updateTicket = useUpdateTicket(instanceId, assignment.id);
  const deleteTicket = useDeleteTicket();
  const isSignedOff = (ticket && ticket.signoffs.length > 0);

  const isScaleGenerated = ticket && !!ticket.scaleSource;

  const isEditing = !!ticket;

  // isSignedOff is used in case the driver has signed off but has not refreshed yet
  const readOnly = isSignedOff || (ticket && !ticket.canEdit);

  const onUpload = useFormikOCR(formikRef.current);

  const [
    initialRateUnit,
    initialQuantity,
    defaultQuantityPerRateUnitMap,
  ] = useGetRateAndQuantity(ticket, assignment);


  // TODO: for configurable fields 3.1 we'll need to get the root company ID.
  const companyID = assignment.project && assignment.project.companyId ? `${assignment.project.companyId}` : '0';
  const projectID = assignment.project && assignment.project.id ? `${assignment.project.id}` : null;


  const { configurableFields } = useDriverConfigurableFields(companyID, projectID);
  const ticketConfigurableFields = configurableFields.filter(cf => cf.driverVisible && cf.isTicketField);

  const ticketConfigurableFieldsObj = (ticket && ticket.configurableFields
    && ticket.configurableFields.reduce((acc, field) => {
      const { id, value } = field;
      return { ...acc, [`configurableFields-${id}`]: value };
    }, {})) || {};

  const driverTFs = assignment
    && assignment.assignmentInstances
    && assignment.assignmentInstances.length
    ? assignment.assignmentInstances[0].driverTicketFields
    : [];

  const showGrossWeight = driverTFs.includes(TicketFieldTypes.GrossWeight);
  const showTareWeight = driverTFs.includes(TicketFieldTypes.TareWeight);

  const showLoadTime = driverTFs.includes(TicketFieldTypes.LoadTime) && assignment.hasTimesheet;
  const showDumpTime = driverTFs.includes(TicketFieldTypes.DumpTime) && assignment.hasTimesheet;
  const showArriveAtPickup = driverTFs.includes(TicketFieldTypes.PickUpSiteArrival);
  const showDepartFromDropOff = driverTFs.includes(TicketFieldTypes.DropOffSiteDeparture);

  const showTimesSection = showDumpTime || showLoadTime
    || showArriveAtPickup || showDepartFromDropOff;

  const showJobNumber = driverTFs.includes(TicketFieldTypes.JobNumber);
  const showPickUpSite = driverTFs.includes(TicketFieldTypes.PickUpSite);
  const showDropoffSite = driverTFs.includes(TicketFieldTypes.DropOffSite);
  const showProduct = driverTFs.includes(TicketFieldTypes.Product);
  const showPhase = driverTFs.includes(TicketFieldTypes.Phase);
  const showDeliveryDetails = showJobNumber || showPickUpSite
    || showDropoffSite || showProduct || showPhase;
  const showConfigurableFields = ticketConfigurableFields && ticketConfigurableFields.length > 0;

  const warnings = {};
  if (ticket && ticket.startTime && ticket.endTime) {
    if (isAfter(ticket.startTime, ticket.endTime)) {
      warnings.endTime = intlz('Warning: Dump Time set to next day');
    } else {
      warnings.endTime = ''
    }
  }
  if (ticket && ticket.pickUpArrival && ticket.dropOffDeparture) {
    if (isAfter(ticket.pickUpArrival, ticket.dropOffDeparture)) {
      warnings.dropOffDeparture = intlz('Warning: Arrival Time set to next day');
    } else {
      warnings.dropOffDeparture = ''
    }
  }

  /**
   * function returns false if input is null or undefined otherwise returns false
   * This is we can do a || b checks while making '' truthy since it could be a
   * user specific input. I.e. driver wants to make jobID empty
   * @param input
   * @returns {boolean|*}
   */
  const isNullOrUndefined = (input) => (input === null || input === undefined);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        id: (ticket && ticket.id) || null,
        data: (ticket && ticket.data) || {},
        rotation: (ticket && ticket.rotation) || 0,
        ticketNumber: (ticket && ticket.ticketNumber) || '',
        quantity: initialQuantity,
        gross: (ticket && ticket.gross) || '',
        tare: (ticket && ticket.tare) || '',
        notes: (ticket && ticket.notes) || '',
        // Use the ticket's rate/unit, or the assignment's. Fallback to the empty string
        haulerRate: (ticket
          ? (ticket.haulerRate && ticket.haulerRate.toFixed(2))
          : (assignment && assignment.haulerRate && assignment.haulerRate.toFixed(2)))
          || '',
        haulerRateUnit: initialRateUnit,
        // This is received from the backend and passed in the input
        // when adding or updating the photo
        ocrData: null,
        startTime: (ticket && ticket.startTime) || null,
        endTime: (ticket && ticket.endTime) || null,
        pickUpArrival: (ticket && ticket.pickUpArrival) || null,
        dropOffDeparture: (ticket && ticket.dropOffDeparture) || null,
        jobNumber: (ticket && !isNullOrUndefined(ticket.jobNumber))
          ? ticket.jobNumber
          : (assignment && assignment.project && assignment.project.jobNumber) || null,
        pickUpSiteAddress: (ticket
          ? (ticket.pickUp || '')
          : ((assignment && assignment.pickUpSite && `${assignment.pickUpSite.name} - ${assignment.pickUpSite.address}`)
            || assignment.pickUp || '')),
        pickUpSiteID: ((ticket && ticket.pickUpSite)
          ? (ticket.dropOffSite.id || null)
          : ((assignment && assignment.pickUpSite && assignment.pickUpSite.id) || null)),
        dropOffSiteAddress: (ticket
          ? (ticket.dropOff || '')
          : ((assignment && assignment.dropOffSite && `${assignment.dropOffSite.name} - ${assignment.dropOffSite.address}`)
            || assignment.dropOff || '')),
        dropOffSiteID: ((ticket && ticket.dropOffSite)
          ? (ticket.dropOffSite.id || null)
          : ((assignment && assignment.dropOffSite && assignment.dropOffSite.id) || null)),
        product: (ticket && !isNullOrUndefined(ticket.product))
          ? ticket.product
          : (assignment && assignment.product) || null,
        phase: (ticket && !isNullOrUndefined(ticket.phase))
          ? ticket.phase
          : (assignment && assignment.phase) || null,
        pendingAttachments: [],
        deletedAttachmentIDs: [],
        configurableFields: (ticket && ticket.configurableFields) || [],
        ...ticketConfigurableFieldsObj,
      }}
      validateOnMount
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (!!values.startTime && !!values.endTime) {
          if (isAfter(values.startTime, values.endTime)) {
            warnings.endTime = intlz('Warning: Dump Time set to next day');
          } else {
            warnings.endTime = '';
          }
        }

        if (!!values.pickUpArrival && !!values.dropOffDeparture) {
          if (isAfter(values.pickUpArrival, values.dropOffDeparture)) {
            warnings.dropOffDeparture = intlz('Warning: Arrival Time set to next day');
          } else {
            warnings.dropOffDeparture = '';
          }
        }

        if (!!values.quantity && !isValidFloat(values.quantity)) {
          errors.quantity = intlz('Please enter a valid quantity or make this empty.');
        }

        if (!!values.tare && !isValidFloat(values.tare)) {
          errors.tare = 'Please enter a valid tare weight or make this empty.';
        }
        if (!!values.gross && !isValidFloat(values.gross)) {
          errors.gross = 'Please enter a valid gross weight or make this empty.';
        }

        return errors;
      }}
      onSubmit={(values, formik) => {
        if (!ticket) {
          addTicket(values);
          formik.resetForm();
          onComplete();
        } else {
          updateTicket(values);
          formik.resetForm();
          onComplete();
        }
      }}
    >
      {({
        setFieldValue,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <Form>
          <div className={classNames(readOnly && styles.paddedHeader)}>
            <TicketHeader
              ticketNumber={ticket && ticket.ticketNumber}
              treadID={isEditing && ticket && ticket.id}
              isScaleGenerated={isScaleGenerated}
            />
          </div>
          {!!values.data.getURL && (
            <div className={styles.ticketImageContainer}>
              <TicketZoomer
                src={values.data.getURL}
                rotation={values.rotation}
                filename={values.data.filename}
                mimeType={values.data.mimeType}
              >
                {!readOnly && (
                  <ClickableDiv
                    className={styles.removePhotoButton}
                    onClick={() => {
                      setFieldValue('rotation', 0);
                      setFieldValue('data', {});
                    }}
                  >
                    <Close />
                  </ClickableDiv>
                )}
              </TicketZoomer>
              <Spinner visible={submitting} />
            </div>
          )}
          {!values.data.getURL && !readOnly && (
            <div>
              <OCRUpload
                setSubmitting={setSubmitting}
                ticket={values}
                instanceId={instanceId}
                onUpload={onUpload}
                shouldRunOCR={!isScaleGenerated}
              />
              <Spinner visible={submitting} />
            </div>
          )}
          <FormikTextInput
            name="ticketNumber"
            label={intlz('Ticket Number')}
            placeholder={intlz('ex. 1234')}
            disabled={isScaleGenerated}
            readOnly={readOnly}
            hint={(ticket && !readOnly) ? (`${intlz('Tread ID')} ${ticket.id}`) : ''}
            id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.TicketNumber)}
          />
          <div className={styles.ticketFormColumns}>
            <FormikTextInput
              name="quantity"
              label={intlz('Quantity')}
              placeholder="0.00"
              disabled={isScaleGenerated}
              readOnly={readOnly}
              id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.Quantity)}
            />
            <FormikDropdown
              name="haulerRateUnit"
              label={intlz('Rate Unit')}
              isDisabled={isScaleGenerated}
              readOnly={readOnly}
              options={rateUnitOptions}
              id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.HaulerRateUnit)}
              onChange={(newRateUnit) => {
                const defaultForOldRateUnit = defaultQuantityPerRateUnitMap[values.haulerRateUnit];

                // override quantity with a default value only when quantity has not
                // been manually updated
                // i.e. quantity is the same as the default quantity
                if (values.quantity === defaultForOldRateUnit) {
                  const defaultQuantityForNewRateUnit = defaultQuantityPerRateUnitMap[newRateUnit];
                  setFieldValue('quantity', defaultQuantityForNewRateUnit);
                }

                setFieldValue('haulerRateUnit', newRateUnit);
              }}
            />
          </div>
          <div className={styles.ticketFormColumns}>
            {showGrossWeight && (
              <FormikTextInput
                name="gross"
                label={intlz('Gross')}
                placeholder="0.00"
                readOnly={readOnly}
                disabled={isScaleGenerated}
                id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.Gross)}
                styleName="positiveFloat"
              />
            )}
            {showTareWeight && (
              <FormikTextInput
                name="tare"
                label={intlz('Tare')}
                placeholder="0.00"
                readOnly={readOnly}
                disabled={isScaleGenerated}
                id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.Tare)}
                styleName="positiveFloat"
              />
            )}
          </div>
          {showTimesSection && (
            <div>
              <CardDivider className={styles.divider} />
              <div className={styles.subtitle}>{intlz('Times')}</div>
              <div className={styles.inputContainer}>
                {showArriveAtPickup && (
                  <div className={styles.inputStyles}>
                    <FormikMobileTimePicker
                      name="pickUpArrival"
                      label={intlz('Arrive at Pick Up')}
                      placeholder="Ex. 8:25am"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.PickUpArrival)}
                    />
                  </div>
                )}
                {showLoadTime && (
                  <div className={styles.inputStyles}>
                    <FormikMobileTimePicker
                      name="startTime"
                      label={intlz('Load Time')}
                      placeholder="Ex. 7:25am"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.StartTime)}
                    />
                  </div>
                )}
                {showDepartFromDropOff && (
                  <div className={styles.inputStyles}>
                    <FormikMobileTimePicker
                      name="dropOffDeparture"
                      label={intlz('Arrive at Drop Off')}
                      placeholder="Ex. 8:25am"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.DropOffDeparture)}
                      warnings={warnings}
                      hideError={true}
                      showWarning={true}
                    />
                  </div>
                )}
                {showDumpTime && (
                  <div className={styles.inputStyles}>
                    <FormikMobileTimePicker
                      name="endTime"
                      label={intlz('Dump Time')}
                      placeholder="Ex. 8:25am"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.EndTime)}
                      warnings={warnings}
                      hideError={true}
                      showWarning={true}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {showDeliveryDetails && (
            <div>
              <CardDivider className={styles.divider} />
              <div className={styles.subtitle}>{intlz('Delivery Details')}</div>
              <div className={styles.inputContainer}>
                {showJobNumber && (
                  <div className={styles.inputStylesMaxed}>
                    <FormikTextInput
                      name="jobNumber"
                      label={intlz('Job No.')}
                      placeholder="Ex. 01-22499"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.JobNumber)}
                    />
                  </div>
                )}
                {showPickUpSite && (
                  <div className={styles.inputStyles}>
                    <FormikTextInput
                      name="pickUpSiteAddress"
                      label={intlz('Pick Up Site')}
                      placeholder="Ex. LAGAUVIN PIT"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.PickUpSite)}
                    />
                  </div>
                )}
                {showDropoffSite && (
                  <div className={styles.inputStyles}>
                    <FormikTextInput
                      name="dropOffSiteAddress"
                      label={intlz('Drop Off Site')}
                      placeholder="Ex. MOODIE QUARRY"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.DropOffSite)}
                    />
                  </div>
                )}
                {showProduct && (
                  <div className={styles.inputStyles}>
                    <FormikTextInput
                      name="product"
                      label={intlz('Product')}
                      placeholder="Ex. Asphalt"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.Product)}
                    />
                  </div>
                )}
                {showPhase && (
                  <div className={styles.inputStyles}>
                    <FormikTextInput
                      name="phase"
                      label={intlz('Phase')}
                      placeholder="Ex. 20-000199"
                      readOnly={readOnly}
                      id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.Phase)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <CardDivider className={styles.divider} />
          
          <div className={styles.notesMargin}>
            <FormikTextArea
              name="notes"
              id={ID.create(ID.Areas.Tickets, ID.Types.Input, ID.Labels.Notes)}
              label={intlz('Notes')}
              placeholder={intlz('Notes for ticket.')}
              readOnly={readOnly}
              error={errors.addEditForm}
            />
          </div>

          <div>
            <AttachmentUpload
              setSubmitting={() => { }}
              ticket={ticket}
              instanceId={instanceId}
              onUpload={(attachment) => {
                const ref = formikRef.current;
                const attachments = [...ref.values.pendingAttachments, attachment];
                setFieldValue('pendingAttachments', attachments);
              }}
              readOnly={isSignedOff}
            />
          </div>

          {showConfigurableFields && (
            <>
              <div className={styles.subtitle}>{intlz('Additional Fields')}</div>
              <CardDivider className={styles.divider} />
              <ConfigurableFields
                name="configurableFields"
                fields={ticketConfigurableFields}
                readOnly={readOnly}
                formikValues={values}
                formikSetFieldValue={setFieldValue}
                formikTouched={touched}
                formikSetFieldTouched={setFieldTouched}
              />
            </>
          )}
          {displayJobInfo && (
            <SignOffPageJobInfoDetails
              assignmentId={assignment.id}
            />
          )}
          {isSignedOff && signoffEnabled && ticket && (
            <FormField label={intlz('Sign Off')}>
              <div className={styles.signoffContainer}>
                {ticket.signoffs[0].signoffName}
                {<SignedOffTag date={ticket.signoffs[0].signoffDate} />}
              </div>
            </FormField>
          )}

          {isEditing && onRemove && !isSignedOff && (
            <div className={styles.deleteTicketsButtonParent}>
              <TextOnlyButton
                className={styles.deleteTicketButton}
                text={intlz('Delete Ticket')}
                onClick={() => {
                  deleteTicket(values.id);
                  onRemove();
                }}
              />
            </div>
          )}
          {!readOnly && (
            <div className={styles.stickyMenuToBottom}>
              <div className={styles.ticketFormButtonColumns}>
                <div className={styles.cardDividerBottomSticky}>
                  <CardDivider className={styles.bottomStickyDivider} />
                </div>
                <Button
                  styleName={currentStep === Steps.getSignature ? 'neutral' : 'primary'}
                  text={!isEditing ? intlz('Add') : intlz('Update')}
                  type="submit"
                  disabled={submitting || !isValid}
                  fullWidth
                  large
                  id={ID.create(ID.Areas.Tickets, ID.Types.Button, ID.Labels.AddTicket)}
                />
                {signoffEnabled && onSignoff && (
                  <Button
                    className={styles.signoffButton}
                    icon={<Pen />}
                    rightIcon={false}
                    text={intlz('Continue to Sign Off')}
                    onClick={() => {
                      if (isEditing) {
                        updateTicket(values)
                          .then(onSignoff);
                      } else {
                        addTicket(values)
                          .then((newTicket) => setSelectedTicketId(newTicket.id))
                          .then(onSignoff);
                      }
                    }}
                    disabled={!isValid}
                    fullWidth
                    large
                  />
                )}
              </div>

            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

AddEditTicketForm.defaultProps = {
  onSignoff: null,
  onRemove: null,
  assignment: null,
  displayJobInfo: false,
  signoffEnabled: false,
  currentStep: '',
};

export default AddEditTicketForm;
